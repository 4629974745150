import { BoardEntryEdit } from '@/models/settouMessageBoard';
import { editingBoardEntryDefault } from '@/components/SettouMessageBoard/consts/board_entry_edit_modal';
import { BoardTab } from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
import { boardTabDefault } from '@/components/SettouMessageBoard/consts/settou_message_board';

export const ONE_DAY_MSEC = 1000 * 60 * 60 * 24;
export const BOARD_ENTRY_ATTACHED_TO_CONTENT = 'content';
export const BOARD_ENTRY_ATTACHED_TO_REPLY = 'reply';
export interface SearchParams {
  dateFrom: Date;
  dateTo: Date;
  han?: number | null;
  honshaReportNeeded?: number | null;
  reportToHonsha?: number | null;
  companyName?: string | null;
}

export const convSearchParamDateFromTo = (dateFrom: Date, dateTo: Date): [Date, Date] => {
  return [dateFrom, new Date(dateTo.getTime() + ONE_DAY_MSEC - 1)];
};

export const getEditingBoardEntryDefault = (): BoardEntryEdit => {
  return JSON.parse(JSON.stringify(editingBoardEntryDefault));
};

export const getBoardTabDefault = (): BoardTab => {
  return { ...boardTabDefault };
};
