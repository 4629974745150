import { Feature } from 'ol';
import { Style, Stroke } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { LineString, Point } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent } from 'ol/extent';
import { Color } from 'ol/color';
import ExtremeMapAbstractLayerManager, {
  LayerWithInfo,
  CirclePointWithTextStyleParams,
  DirectionArrowStyleParams,
  DIRECTION_POINTER_INTERVAL,
} from '@/lib/ExtremeMapAbstractLayerManager';
import { FeatureExt } from '@/lib/OlMapManager';
import { NamedVectorLayer } from '@/lib/OlMapWrapper';
import { JohaisetsuReportExt } from '@/models/apis/johaisetsu/settouSagyouReport/johaisetsuReportResponse';
import { JohaisetsuMtxExt } from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';
import {
  JOHAISETSU_CAR_STATUS_RUNNING,
  JOHAISETSU_CAR_STATUS_WORKING,
} from '@/consts/johaisetsu_car';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';
import { typeRemarks, JOHAISETSU_TYPE_ENSUI_SANPU } from '@/lib/johaisetsu/johaisetsuCommonUtil';
import { arrayUnique } from '@/lib/arrayUtil';

interface CreateMtxPointParams {
  mtx: JohaisetsuMtxExt;
  lineColor: string;
  radius: number;
  text: string;
  zIndex: number;
  rotation: number;
}

const ZOOM_LEVEL_1000M = 13;
const MOVING_LINE_COLOR = 'rgba(127, 127, 127, 0.8)';
const ZOOM_LEVEL_LTE_1000M_LINE_WIDTH = 4;
const ZOOM_LEVEL_GT_1000M_LINE_WIDTH = 7;
const MOVING_MTX_POINT_FILL_COLOR: Color = [157, 157, 157, 1.0];
const MOVING_MTX_POINT_STROKE_COLOR: Color = [101, 101, 101, 1.0];
const ZOOM_LEVEL_LTE_1000M_MTX_POINT_RADIUS = 2.4;
const ZOOM_LEVEL_GT_1000M_MTX_POINT_RADIUS = 3.6;

export default class ExtremeMapJohaisetsuReportLayerManager extends ExtremeMapAbstractLayerManager {
  layerName: string;
  extremeMap: InstanceType<typeof ExtremeMap> | null;
  johaisetsuReport: JohaisetsuReportExt | null;
  zoom: number;
  constructor() {
    super();
    this.layerName = 'johaisetsu-reports';
    this.layerInfo.onLayerClick = () => {};
    this.extremeMap = null;
    this.johaisetsuReport = null;
    this.zoom = 10;
  }

  createLineFeature_(points: Coordinate[], zIndex: number, status: string, color: string): Feature | null {
    // need at least 2 points to make a line.
    if (points.length < 2) {
      return null;
    }
    const line = new Feature({
      geometry: new LineString(points),
    });
    const lineColor = status === JOHAISETSU_CAR_STATUS_RUNNING ? MOVING_LINE_COLOR : this.hexToRgba(color, 100);
    const lineWidth = this.zoom > ZOOM_LEVEL_1000M ? ZOOM_LEVEL_GT_1000M_LINE_WIDTH : ZOOM_LEVEL_LTE_1000M_LINE_WIDTH;
    line.setStyle(new Style({
      stroke: new Stroke({
        color: lineColor,
        width: lineWidth,
      }),
      zIndex: zIndex,
    }));
    (line as FeatureExt).onClickFunc = () => {};
    return line;
  }

  createCarLineFeatures_(mtxs: JohaisetsuMtxExt[], zIndexStart: number):
    { lineFeats: Feature[]; lineZIndex: number; posCoords: Coordinate[] } {
    const lineFeats: Feature[] = [];
    const posCoords: Coordinate[] = [];
    let lineZIndex = zIndexStart;
    let movingPoints: Coordinate[] = [];
    let johaisetsuPoints: Coordinate[] = [];
    let currentStatus = JOHAISETSU_CAR_STATUS_RUNNING;
    const len = mtxs.length;
    mtxs.forEach((mtx, i) => {
      const point = this.convCoord(mtx);
      if (mtx.status === JOHAISETSU_CAR_STATUS_RUNNING) {
        movingPoints.push(point);
      }
      if (mtx.status === JOHAISETSU_CAR_STATUS_WORKING) {
        johaisetsuPoints.push(point);
      }
      posCoords.push(point);
      if (i === 0) {
        currentStatus = mtx.status;
        return;
      }
      if (currentStatus !== mtx.status || i === len - 1) {
        const typeRemark = typeRemarks[mtx.data_type ?? 'sweeper_soukou'];
        const lineColor = typeRemark.color;
        if (currentStatus === JOHAISETSU_CAR_STATUS_RUNNING) {
          movingPoints.push(point);
          const lineFeat = this.createLineFeature_(movingPoints, lineZIndex++, currentStatus, lineColor);
          if (lineFeat) {
            lineFeats.push(lineFeat);
          }
          movingPoints = [];
        }
        if (currentStatus === JOHAISETSU_CAR_STATUS_WORKING) {
          johaisetsuPoints.push(point);
          const lineFeat = this.createLineFeature_(johaisetsuPoints, lineZIndex++, currentStatus, lineColor);
          if (lineFeat) { lineFeats.push(lineFeat); }
          johaisetsuPoints = [];
        }
        currentStatus = mtx.status;
      }
    });
    return { lineFeats, lineZIndex, posCoords };
  }

  createMtxPointFeatures_(
    mtxs: JohaisetsuMtxExt[],
    zIndexStart: number,
  ): { mtxPointFeats: Feature[]; mtxPointZIndex: number } {
    const mtxPointFeats: Feature[] = [];
    let mtxPointZIndex = zIndexStart;
    let lineColor = '';
    const radius =
      this.zoom > ZOOM_LEVEL_1000M
        ? ZOOM_LEVEL_GT_1000M_MTX_POINT_RADIUS
        : ZOOM_LEVEL_LTE_1000M_MTX_POINT_RADIUS;
    if (mtxs.length === 0) return { mtxPointFeats, mtxPointZIndex };

    const uniqueMtxs = arrayUnique(mtxs, (mtx) => `${mtx.lon}-${mtx.lat}`);

    // 最初と最後のpointをそれ以外のpointよりも上に表示するために、最初と最後のpointは後に追加する
    for (let idx = 1; idx < uniqueMtxs.length - 1; idx++) {
      const mtx = uniqueMtxs[idx];
      const nextMtx = uniqueMtxs[idx + 1];
      const rotation = this.getBearing(mtx, nextMtx);
      const typeRemark = typeRemarks[mtx.data_type ?? JOHAISETSU_TYPE_ENSUI_SANPU];
      lineColor = typeRemark.color;
      const text = '';
      const showDirectionIcon = idx % DIRECTION_POINTER_INTERVAL === 0;
      const mtxPointParams: CreateMtxPointParams = {
        mtx,
        radius,
        text,
        zIndex: mtxPointZIndex++,
        lineColor,
        rotation,
      };
      const feat = showDirectionIcon
        ? this.createMtxDirectionArrowFeature_(mtxPointParams)
        : this.createMtxCirclePointFeature_(mtxPointParams);
      mtxPointFeats.push(feat);
    }
    const firstMtxPointParams: CreateMtxPointParams = {
      mtx: uniqueMtxs[0],
      radius: radius * 2.0,
      text: 'S',
      zIndex: mtxPointZIndex++,
      lineColor,
      rotation: 0,
    };
    const firstFeat = this.createMtxCirclePointFeature_(firstMtxPointParams);
    const lastMtxPointParams: CreateMtxPointParams = {
      mtx: uniqueMtxs[uniqueMtxs.length - 1],
      radius: radius * 2.0,
      text: 'E',
      zIndex: mtxPointZIndex++,
      lineColor,
      rotation: 0,
    };
    const lastFeat = this.createMtxCirclePointFeature_(lastMtxPointParams);
    mtxPointFeats.push(firstFeat, lastFeat);
    return { mtxPointFeats, mtxPointZIndex };
  }

  createMtxCirclePointFeature_({
    mtx,
    radius,
    text,
    zIndex,
    lineColor,
  }: CreateMtxPointParams): Feature {
    const feat = new Feature({
      geometry: new Point(this.convCoord(mtx)),
    });
    const fillColor =
      mtx.status === JOHAISETSU_CAR_STATUS_RUNNING
        ? MOVING_MTX_POINT_FILL_COLOR
        : this.hexToRgba(lineColor, 0);
    const strokeColor =
      mtx.status === JOHAISETSU_CAR_STATUS_RUNNING
        ? MOVING_MTX_POINT_STROKE_COLOR
        : this.hexToRgba(lineColor, 0);
    const pointStyleParams: CirclePointWithTextStyleParams = {
      radius,
      fillColor,
      strokeColor,
      zIndex,
      text,
    };
    const featStyle = text
      ? this.getCircleWithTextPointStyle(pointStyleParams)
      : this.getCirclePointStyle(pointStyleParams);
    feat.setStyle(featStyle);
    (feat as FeatureExt).onClickFunc = () => {
      if (this.extremeMap) {
        this.extremeMap.onClickJohaisetsuMtx(mtx);
      }
    };
    return feat;
  }
  createMtxDirectionArrowFeature_({
    mtx,
    zIndex,
    rotation,
  }: CreateMtxPointParams): Feature {
    const feat = new Feature({
      geometry: new Point(this.convCoord(mtx)),
    });
    const pointStyleParams: DirectionArrowStyleParams = {
      zIndex,
      rotation,
    };
    const featStyle = this.getDirectionArrowStyle(pointStyleParams);
    feat.setStyle(featStyle);
    (feat as FeatureExt).onClickFunc = () => {
      if (this.extremeMap) {
        this.extremeMap.onClickJohaisetsuMtx(mtx);
      }
    };
    return feat;
  }

  createReportLayer_(reportExt: JohaisetsuReportExt): void {
    const features: Feature[] = [];
    let zIndex = 1;
    const reportPosCoords: Coordinate[] = [];

    // 車両移動の軌跡を描画する
    const { lineFeats, lineZIndex, posCoords } = this.createCarLineFeatures_(reportExt.mtxsExt, zIndex);
    features.push(...lineFeats);
    zIndex = lineZIndex;
    reportPosCoords.push(...posCoords);

    // mtxsの点を描画する
    const { mtxPointFeats, mtxPointZIndex } = this.createMtxPointFeatures_(reportExt.mtxsExt, zIndex);
    features.push(...mtxPointFeats);
    zIndex = mtxPointZIndex;

    const layer: NamedVectorLayer = new VectorLayer({
      source: new VectorSource({features: features as Feature[]}),
    });
    layer.name = 'johaisetsu-reports';
    const extent = reportPosCoords.length > 0 ? boundingExtent(reportPosCoords) : null;

    this.layer = layer;
    this.layerInfo.extent = extent;
    this.layerInfo.onLayerClick = ({ event, feature }) => {
      // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
      if (!event || event.originalEvent.defaultPrevented) { return; }
      event.preventDefault();
      if (feature) {
        (feature as FeatureExt).onClickFunc();
      }
    };
  }

  prepareLayer(
    extremeMap: InstanceType<typeof ExtremeMap>,
    reportExt: JohaisetsuReportExt,
    zoom: number,
  ): LayerWithInfo {
    this.extremeMap = extremeMap;
    this.johaisetsuReport = reportExt;
    this.zoom = zoom;
    this.createReportLayer_(reportExt);
    return this.getLayer();
  }

  refreshLayerOnZoomChange(): void {
    if (!this.extremeMap) return;
    if (!this.johaisetsuReport) return;
    this.extremeMap.showJohaisetsuReportLayer(this.johaisetsuReport, false);
  }

  hexToRgba(hex: string, amount: number): [number, number, number, number] {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');
    // Parse the r, g, b values
    const bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    r = Math.min(255, r + amount);
    g = Math.min(255, g + amount);
    b = Math.min(255, b + amount);
    // Return the RGBA array, assuming full opacity
    return [r, g, b, 1.0];
  }
}
