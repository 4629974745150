import { reactive, nextTick, ref, Ref } from '@vue/composition-api';

import cleaningCarApi from '@/apis/cleaning_car';

import {
  CarInfo,
  CarState,
  SearchState,
} from '@/models/cleaningMap';
import { parseDateTimeString } from '@/lib/dateTimeUtil';
import {
  createInitialCarsData,
  convData,
  shouldShowCar,
  deselectReportItems,
  loadCleaningMtxs,
} from '@/components/CleaningMap/utils';
import { createInjection } from '@/lib/createInjection';
import { IntervalID } from '@/lib/requestAnimationFrame';
import { CleaningCarExt } from '@/models';
import { CLEANING_CAR_MAX_MTXS } from '@/components/CleaningMap/consts/cleaning_map';
import {
  CLEANING_CAR_STATUS_RUNNING,
  CLEANING_CAR_STATUS_WORKING,
  CLEANING_CAR_STATUS_STOPPED,
} from '@/components/CleaningMap/consts/cleaning_car';

const RECENT_DAYS = 60;

interface InjectedState {
  carState: CarState;
  searchState: SearchState;
}
export interface UseCleaningCarsResult {
  carState: CarState;
  startCarUpdateInterval: () => Promise<void>;
  stopCarUpdateInterval: () => void;
  filterCars: () => void;
  getSelectedCar: () => CleaningCarExt | null;
  selectCar: (carId: number) => Promise<void>;
  deselectCar: (carId: number) => boolean;
  deselectSelectedCarReportPhotos: () => void;
  clearCars: () => void;
  redrawCarLayerWatchValue: Ref<number>;
  notifyRedrawCarLayerNeeded: () => void;
}

const { provide, inject } = createInjection<InjectedState>('cleaningMap/useCleaningCars');

export function provideCleaningCars(searchState: SearchState): InjectedState {
  const carState = reactive<CarState>({
    cars: [],
    carsUpdatedAt: new Date(),
  });
  const injectedState = { carState, searchState };
  provide(injectedState);
  return injectedState;
}

export default function useCleaningCars(defaultInjectedState?: InjectedState): UseCleaningCarsResult {
  const injectedState = defaultInjectedState ?? inject();
  const carState = injectedState.carState;
  const searchState = injectedState.searchState;
  let carUpdateTimer: IntervalID | null = null;
  const redrawCarLayerWatchValue = ref<number>(0);

  const startCarUpdateInterval = async() => {
    window.clearRequestInterval(carUpdateTimer);
    await refreshCars();
    carUpdateTimer = window.requestInterval(async() => {
      await refreshCars();
    }, 10000);
  };
  const stopCarUpdateInterval = () => {
    window.clearRequestInterval(carUpdateTimer);
  };

  const notifyRedrawCarLayerNeeded = () => {
    redrawCarLayerWatchValue.value += 1;
  };

  const filterCars = () => {
    // 検索ボタンが押された時に、検索条件を確定する
    searchState.decidedSearchConds = { ...searchState.searchConds };
    carState.cars.forEach(car => {
      car.shouldShow = shouldShowCar(car, searchState.decidedSearchConds, searchState.cleaningCompanyAreaMap);
    });
  };

  const refreshCars = async() => {
    const { data } = await cleaningCarApi.getCleaningCars();
    const statusOrder = [
      CLEANING_CAR_STATUS_WORKING,
      CLEANING_CAR_STATUS_RUNNING,
      CLEANING_CAR_STATUS_STOPPED,
    ];
    const findIndex = (status: string) => {
      const index = statusOrder.indexOf(status);
      return index === -1 ? statusOrder.length : index;
    };
    data.sort((a, b) => {
      const indexA = findIndex(a.status);
      const indexB = findIndex(b.status);
      return indexA - indexB;
    });
    const recentTs = new Date().setDate(new Date().getDate() - RECENT_DAYS);
    const recentData = data.filter(cleaningCar => {
      try {
        const tsDate = parseDateTimeString(cleaningCar.ts);
        return tsDate.getTime() > recentTs;
      } catch (e) {
        return false;
      }
    });

    // carState.carsが空の場合は固定値、2回目以降は自分で持ってるやつ
    const isInitial = carState.cars.length === 0;
    if (isInitial) {
      carState.cars = createInitialCarsData(recentData);
    }
    const currentCarInfoMap = carState.cars.reduce((acc: Record<string, CarInfo>, e) => {
      acc[e.id] = {
        isSelected: e.isSelected,
        selectedPhotoId: e.reportExt?.photosExt.find(photo => photo.isSelected)?.id ?? -1,
        selectedMtxTs: e.reportExt?.mtxsExt.find(mtx => mtx.isSelected)?.ts ?? '',
        savedImageMap: e.reportExt?.photosExt.reduce((acc2: Record<number, string>, photo) => {
          acc2[photo.id] = photo.savedImage || '';
          return acc2;
        }, {}) ?? {},
        loadedMtxs: e.reportExt?.mtxsExt ?? [],
      };
      return acc;
    }, {});

    carState.cars = convData({
      cars: recentData,
      currentCarInfoMap,
      carKindMap: window.master.lovs.car_kind.map,
      cleaningCompanies: window.cleaningMaster.cleaningCompanies,
    });
    filterCars();
    carState.carsUpdatedAt = new Date();
    nextTick(() => {
      notifyRedrawCarLayerNeeded();
    });
  };

  const getSelectedCar = (): CleaningCarExt | null => {
    return carState.cars.find(e => e.isSelected) ?? null;
  };

  const selectCar = async(carId: number): Promise<void> => {
    for (const car of carState.cars) {
      car.isSelected = car.id === carId;
      const report = car.reportExt;
      if (!report) continue;

      if (!report.isMtxsLoaded) {
        await loadCleaningMtxs([report], CLEANING_CAR_MAX_MTXS);
      }
    }
  };

  const deselectCar = (carId: number): boolean => {
    const car = getSelectedCar();
    if (!car || car.id !== carId) return false;

    car.isSelected = false;
    if (car.reportExt) {
      deselectReportItems(car.reportExt);
    }
    return true;
  };

  const deselectSelectedCarReportPhotos = (): void => {
    const car = getSelectedCar();
    if (!car || !car.reportExt) return;

    deselectReportItems(car.reportExt);
  };

  const clearCars = (): void => {
    carState.cars.forEach(car => {
      if (!car.reportExt) return;
      car.reportExt.photosExt.forEach(async(photo) => {
        if (!photo.savedImage) return;
        return URL.revokeObjectURL(photo.savedImage);
      });
    });
    carState.cars = [];
  };

  return {
    carState,
    startCarUpdateInterval,
    stopCarUpdateInterval,
    filterCars,
    getSelectedCar,
    selectCar,
    deselectCar,
    deselectSelectedCarReportPhotos,
    clearCars,
    redrawCarLayerWatchValue,
    notifyRedrawCarLayerNeeded,
  };
}
