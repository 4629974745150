


import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
} from '@vue/composition-api';
import { BoardEntryFile } from '@/models/apis/settouMessageBoard/settouMessageBoardResponse';
import { fetchFileAsObjectInfo, fileTypeIconFromFilePath } from '@/lib/fileHelper';
import { downloadObjectUrl } from '@/lib/downloadHelper';
import { lookup as lookupMimeTypes } from 'mime-types';

interface AttachedFilesViewAreaState {
  previewImage: string;
  images: BoardEntryFile[];
  files: BoardEntryFile[];
}

export default defineComponent({
  props: {
    boardEntryFiles: {
      type: Array as PropType<BoardEntryFile[]>,
      required: true,
    },
    attachedTo: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive<AttachedFilesViewAreaState>({
      previewImage: '',
      images: [],
      files: [],
    });
    const onClickImage = () => {
      emit('click-image', state.images);
    };
    const downloadFile = async(file: BoardEntryFile) => {
      const savedFile = await fetchFileAsObjectInfo(file.file_path);
      if (!savedFile || !savedFile.url) return;
      downloadObjectUrl(savedFile.url, file.file_name);
    };
    const getPreviewImage = async(images: BoardEntryFile[]): Promise<string> => {
      if (!images.length) return '';
      const imageObjInfo = await fetchFileAsObjectInfo(images[0].file_path);
      if (!imageObjInfo || !imageObjInfo.url) return '';
      return imageObjInfo.url;
    };
    onMounted(async() => {
      const [images, others] = props.boardEntryFiles
        .filter(file => file.attached_to === props.attachedTo)
        .map(file => ({ file, mimeType: lookupMimeTypes(file.file_path) }))
        .reduce<[BoardEntryFile[], BoardEntryFile[]]>(([images, others], e) => {
          if (!e.mimeType) return [images, others];
          return e.mimeType.includes('image/') ? [[...images, e.file], others] : [images, [...others, e.file]];
        }, [[], []]);
      state.images = images;
      state.files = others;
      // プレビュー用の画像(1枚目)を取得
      state.previewImage = await getPreviewImage(state.images);
    });
    onUnmounted(() => {
      URL.revokeObjectURL(state.previewImage);
    });

    return {
      state,
      onClickImage,
      downloadFile,
      fileTypeIconFromFilePath,
    };
  },
});
