import axios, { AxiosResponse } from 'axios';
import * as utils from './apiUtils';
import {
  CleaningMTX,
  CleaningMtxsCreateCleaningMTXsParams,
  CleaningMtxGetParams,
} from '@/models/apis/cleaning/cleaningMtxsRequest';
import { EmptyResponse } from '@/models/index';

export default {
  createCleaningMTXs(params: CleaningMtxsCreateCleaningMTXsParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/cleaning_mtxs'), params);
  },
  async getCleaningMtxs(params: CleaningMtxGetParams): Promise<AxiosResponse<Record<number, CleaningMTX[]>>> {
    const response = await axios.get(utils.getApiUrl(`/cleaning_mtxs`), {
      params: params || {},
    });
    const data = response.data as Record<number, CleaningMTX[]>;
    Object.values(data).forEach((mtxArray: CleaningMTX[]) => {
      mtxArray.forEach((mtx: CleaningMTX) => {
        mtx.lat = Number(mtx.lat);
        mtx.lon = Number(mtx.lon);
      });
    });
    return response;
  },
};
