import { Feature } from 'ol';
import { Icon, Style, Stroke, Fill, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { LineString, Point } from 'ol/geom';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent } from 'ol/extent';
import { Color } from 'ol/color';
import ExtremeMapAbstractLayerManager, {
  LayerWithInfo,
  CirclePointStyleParams,
  DirectionArrowStyleParams,
  DIRECTION_POINTER_INTERVAL,
} from '@/lib/ExtremeMapAbstractLayerManager';
import { CleaningCarExt, IconPath } from '@/models/index';
import { FeatureExt } from 'src/lib/OlMapManager';
import { NamedVectorLayer } from 'src/lib/OlMapWrapper';
import { CleaningMtxExt } from '@/models/apis/cleaning/cleaningMtxsRequest';
import { CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';
import {
  CLEANING_CAR_STATUS_RUNNING,
  CLEANING_CAR_STATUS_WORKING,
  CLEANING_CAR_STATUS_STOPPED,
} from '@/components/CleaningMap/consts/cleaning_car';
import { PHOTO_TYPE_DEFECT, PHOTO_TYPE_CLEANING } from '@/components/CleaningMap/consts/cleaning_photo';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';

interface CleaningCarIconPath {
  carIconPath: string;
  selectedFrameIconPath: string;
}

interface Opts {
  hideCarIcons: boolean;
  hideCleaningPhotoIcons: boolean;
  hideDefectPhotoIcons: boolean;
}

const PHOTO_PIN_TEXT_COLOR_MAP: Record<string, string> = {
  [PHOTO_TYPE_DEFECT]: '#000000',
  [PHOTO_TYPE_CLEANING]: '#FFFFFF',
};

const ZOOM_LEVEL_1000M = 13;
const MOVING_LINE_COLOR = 'rgba(127, 127, 127, 0.8)';
const WORKING_LINE_COLOR = 'rgba(56, 241, 215, 0.8)';
const ZOOM_LEVEL_LTE_1000M_LINE_WIDTH = 4;
const ZOOM_LEVEL_GT_1000M_LINE_WIDTH = 7;
const MOVING_MTX_POINT_FILL_COLOR: Color = [157, 157, 157, 1.0];
const MOVING_MTX_POINT_STROKE_COLOR: Color = [101, 101, 101, 1.0];
const WORKING_MTX_POINT_FILL_COLOR: Color = [56, 241, 215, 1.0];
const WORKING_MTX_POINT_STROKE_COLOR: Color = [37, 161, 144, 1.0];
const ZOOM_LEVEL_LTE_1000M_MTX_POINT_RADIUS = 2.4;
const ZOOM_LEVEL_GT_1000M_MTX_POINT_RADIUS = 3.6;

export default class ExtremeMapCleaningCarLayerManager extends ExtremeMapAbstractLayerManager {
  layerName: string;
  opts: Opts;
  extremeMap: InstanceType<typeof ExtremeMap> | null;
  zoom: number;
  constructor(opts: Opts) {
    super();
    this.layerName = 'cleaning-cars';
    this.layerInfo.onLayerClick = () => {};
    this.opts = opts;
    this.extremeMap = null;
    this.zoom = 10;
  }

  getCleaningCarIconPath(car: CleaningCarExt): CleaningCarIconPath {
    const cleaningCarIconPrefix = '/static/img/cleaning/cleaning_car_';
    const statusPrefix = car.isMoving ? CLEANING_CAR_STATUS_RUNNING : CLEANING_CAR_STATUS_STOPPED;
    const carIconPath = `${cleaningCarIconPrefix}${statusPrefix}.png`;
    const selectedFrameIconPath = `/static/img/car_selected.png`;
    return { carIconPath, selectedFrameIconPath };
  }

  getCarStyles(car: CleaningCarExt, zIndex: number): Style[] | null {
    if (!car.shouldShow) {
      return null;
    }

    const ret: Style[] = [];
    const { carIconPath, selectedFrameIconPath } = this.getCleaningCarIconPath(car);

    ret.push(new Style({
      image: new Icon({
        src: carIconPath,
        anchor: [0.5, 0.95],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.25,
      }),
      zIndex,
    }));
    if (car.isSelected) {
      ret.push(new Style({
        image: new Icon({
          src: selectedFrameIconPath,
          anchor: [0.5, 0.95],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.25,
          opacity: 1.0,
        }),
        zIndex: zIndex + 1,
      }));
    }
    if (car.isWorking) {
      ret.push(new Style({
        image: new Icon({
          src: '/static/img/car_is_working.png',
          anchor: [2.5, 6],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.02,
          opacity: 1.0,
        }),
        zIndex: zIndex + 2,
      }));
    }

    return ret;
  }
  getCarFeature(car: CleaningCarExt, zIndex: number): Feature {
    const feat = new Feature({
      geometry: car.pos ? new Point(car.pos) : undefined,
    });
    feat.setId(car.id);
    feat.setStyle(this.getCarStyles(car, zIndex));
    (feat as FeatureExt).onClickFunc = () => {
      if (this.extremeMap) {
        this.extremeMap.onClickCleaningCar(car);
      }
    };
    return feat;
  }

  getIconPath_(photo: CleaningReportPhotoExt): IconPath {
    const iconPath = `/static/img/cleaning/cleaning_report_photo_${photo.photo_type}_pin.png`;
    const selectedFramePath = '/static/img/pin_selected.png';
    return { iconPath, selectedFramePath };
  }

  getResourceStyles_(photo: CleaningReportPhotoExt, zIndex: number): Style[] {
    const ret: Style[] = [];
    const { iconPath, selectedFramePath } = this.getIconPath_(photo);
    if (photo.isSelected) {
      ret.push(new Style({
        image: new Icon({
          src: selectedFramePath,
          anchor: [0.5, 0.82],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.30,
          opacity: 1.0,
        }),
        zIndex: zIndex,
      }));
    }
    ret.push(new Style({
      image: new Icon({
        src: iconPath,
        anchor: [0.5, 0.82],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.30,
      }),
      text: new Text({
        text: photo.disp_order.toString(),
        font: 'bold 16px sans-serif',
        fill: new Fill({ color: PHOTO_PIN_TEXT_COLOR_MAP[photo.photo_type] }),
        offsetX: 0,
        offsetY: -23,
        rotation: 0,
      }),
      zIndex: zIndex,
    }));
    return ret;
  }

  getCarPointFeature(car: CleaningCarExt, zIndexStart: number): Feature {
    const zIndex = zIndexStart;
    const feat = new Feature({
      geometry: new Point(this.convCoord({
        lon: parseFloat(car.lon),
        lat: parseFloat(car.lat),
      })),
    });
    const pointStyleParams: CirclePointStyleParams = {
      radius: 6.0,
      fillColor: [102, 194, 255, 0.8],
      strokeColor: [0, 122, 204, 0.8],
      zIndex,
    };
    const featStyle = this.getCirclePointStyle(pointStyleParams);
    feat.setStyle(featStyle);
    (feat as FeatureExt).onClickFunc = () => {
      if (this.extremeMap) {
        this.extremeMap.onClickCleaningCar(car);
      }
    };
    return feat;
  }

  createLineFeature_(points: Coordinate[], zIndex: number, status: string): Feature | null {
    // need at least 2 points to make a line.
    if (points.length < 2) {
      return null;
    }
    const line = new Feature({
      geometry: new LineString(points),
    });
    const lineColor = status === CLEANING_CAR_STATUS_RUNNING ? MOVING_LINE_COLOR : WORKING_LINE_COLOR;
    const lineWidth = this.zoom > ZOOM_LEVEL_1000M ? ZOOM_LEVEL_GT_1000M_LINE_WIDTH : ZOOM_LEVEL_LTE_1000M_LINE_WIDTH;
    line.setStyle(new Style({
      stroke: new Stroke({
        color: lineColor,
        width: lineWidth,
      }),
      zIndex: zIndex,
    }));
    (line as FeatureExt).onClickFunc = () => {};
    return line;
  }

  createCarLineFeatures_(mtxs: CleaningMtxExt[], zIndexStart: number):
    { lineFeats: Feature[]; lineZIndex: number; posCoords: Coordinate[] } {
    const lineFeats: Feature[] = [];
    const posCoords: Coordinate[] = [];
    let lineZIndex = zIndexStart;
    let movingPoints: Coordinate[] = [];
    let cleaningPoints: Coordinate[] = [];
    let currentStatus = CLEANING_CAR_STATUS_RUNNING;
    const len = mtxs.length;
    mtxs.forEach((mtx, i) => {
      const point = this.convCoord(mtx);
      if (mtx.status === CLEANING_CAR_STATUS_RUNNING) {
        movingPoints.push(point);
      }
      if (mtx.status === CLEANING_CAR_STATUS_WORKING) {
        cleaningPoints.push(point);
      }
      posCoords.push(point);
      if (i === 0) {
        currentStatus = mtx.status;
        return;
      }
      if (currentStatus !== mtx.status || i === len - 1) {
        if (currentStatus === CLEANING_CAR_STATUS_RUNNING) {
          movingPoints.push(point);
          const lineFeat = this.createLineFeature_(movingPoints, lineZIndex++, currentStatus);
          if (lineFeat) {
            lineFeats.push(lineFeat);
          }
          movingPoints = [];
        }
        if (currentStatus === CLEANING_CAR_STATUS_WORKING) {
          cleaningPoints.push(point);
          const lineFeat = this.createLineFeature_(cleaningPoints, lineZIndex++, currentStatus);
          if (lineFeat) { lineFeats.push(lineFeat); }
          cleaningPoints = [];
        }
        currentStatus = mtx.status;
      }
    });
    return { lineFeats, lineZIndex, posCoords };
  }

  createMtxPointFeatures_(mtxs: CleaningMtxExt[], zIndexStart: number):
    { mtxPointFeats: Feature[]; mtxPointZIndex: number } {
    const mtxPointFeats: Feature[] = [];
    let mtxPointZIndex = zIndexStart;
    mtxs.forEach((mtx, idx) => {
      const feat = new Feature({
        geometry: new Point(this.convCoord(mtx)),
      });
      const fillColor =
        mtx.status === CLEANING_CAR_STATUS_RUNNING
          ? MOVING_MTX_POINT_FILL_COLOR
          : WORKING_MTX_POINT_FILL_COLOR;
      const strokeColor =
        mtx.status === CLEANING_CAR_STATUS_RUNNING
          ? MOVING_MTX_POINT_STROKE_COLOR
          : WORKING_MTX_POINT_STROKE_COLOR;
      const radius =
        this.zoom > ZOOM_LEVEL_1000M
          ? ZOOM_LEVEL_GT_1000M_MTX_POINT_RADIUS
          : ZOOM_LEVEL_LTE_1000M_MTX_POINT_RADIUS;
      const hasNext = idx < mtxs.length - 1;
      const rotation = hasNext ? this.getBearing(mtx, mtxs[idx + 1]) : 0;

      const showDirectionIcon = hasNext && idx % DIRECTION_POINTER_INTERVAL === 0;
      if (showDirectionIcon) {
        const pointStyleParams: DirectionArrowStyleParams = {
          zIndex: mtxPointZIndex++,
          rotation,
        };
        const featStyle = this.getDirectionArrowStyle(pointStyleParams);
        feat.setStyle(featStyle);
      } else {
        const pointStyleParams: CirclePointStyleParams = {
          radius,
          fillColor,
          strokeColor,
          zIndex: mtxPointZIndex++,
        };
        const featStyle = this.getCirclePointStyle(pointStyleParams);
        feat.setStyle(featStyle);
      }
      (feat as FeatureExt).onClickFunc = () => {
        if (this.extremeMap) {
          this.extremeMap.onClickCleaningMtx(mtx);
        }
      };
      mtxPointFeats.push(feat);
    });
    return { mtxPointFeats, mtxPointZIndex };
  }

  createPhotoFeatures_(
    photos: CleaningReportPhotoExt[],
    zIndexStart: number,
    hideCleaningPhotoIcons: boolean,
    hideDefectPhotoIcons: boolean,
  ): { photoFeats: Feature[]; photoZIndex: number } {
    const photoFeats: Feature[] = [];
    let photoZIndex = zIndexStart;
    photos.forEach(photo => {
      if (photo.photo_type === PHOTO_TYPE_CLEANING && hideCleaningPhotoIcons) { return; }
      if (photo.photo_type === PHOTO_TYPE_DEFECT && hideDefectPhotoIcons) { return; }
      const firstSamePosPhoto = photo.samePosPhotos.sort((a, b) => a.disp_order - b.disp_order)[0];
      if (photo.id !== firstSamePosPhoto.id) { return; }
      const feat = new Feature({
        geometry: new Point(this.convCoord({
          lon: parseFloat((photo.lon || 0).toString()),
          lat: parseFloat((photo.lat || 0).toString()),
        })),
      });
      feat.setStyle(this.getResourceStyles_(photo, photoZIndex++));
      (feat as FeatureExt).onClickFunc = () => {
        if (this.extremeMap) {
          this.extremeMap.onClickCleaningReportPhoto(photo);
        }
      };
      photoFeats.push(feat);
    });

    return { photoFeats, photoZIndex };
  }

  createCarLayer_(
    cars: CleaningCarExt[],
    { hideCarIcons, hideCleaningPhotoIcons, hideDefectPhotoIcons }: Opts,
  ): void {
    const features: Feature[] = [];
    let zIndex = 1;
    const carPosCoords: Coordinate[] = [];
    // 配列の先頭側にある方を上にする
    cars.reverse().forEach(car => {
      if (!car.shouldShow) {
        return;
      }

      if (car.isSelected && car.reportExt) {
        // 車両移動の軌跡を描画する
        const { lineFeats, lineZIndex, posCoords } = this.createCarLineFeatures_(car.reportExt.mtxsExt, zIndex);
        features.push(...lineFeats);
        zIndex = lineZIndex;
        carPosCoords.push(...posCoords);

        // mtxsの点を描画する
        const { mtxPointFeats, mtxPointZIndex } = this.createMtxPointFeatures_(car.reportExt.mtxsExt, zIndex);
        features.push(...mtxPointFeats);
        zIndex = mtxPointZIndex;

        // 写真のpinを描画する
        const { photoFeats, photoZIndex } = this.createPhotoFeatures_(
          car.reportExt.photosExt,
          zIndex,
          hideCleaningPhotoIcons,
          hideDefectPhotoIcons,
        );
        features.push(...photoFeats);
        zIndex = photoZIndex;
      }

      // 車両の現時点の位置の点を描画する
      const carPointFeat = this.getCarPointFeature(car, zIndex++);
      features.push(carPointFeat);

      if (!hideCarIcons) {
        features.push(this.getCarFeature(car, zIndex++));
        zIndex += 5; // styleが複数の場合が有りえるので適当に増やす
      }
    });

    const layer: NamedVectorLayer = new VectorLayer({
      source: new VectorSource({features: features as Feature[]}),
    });
    layer.name = 'cleaning-cars';
    const extent = carPosCoords.length > 0
      ? boundingExtent(carPosCoords) : null;

    this.layer = layer;
    this.layerInfo.extent = extent;
    this.layerInfo.onLayerClick = ({ event, feature }) => {
      // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
      if (!event || event.originalEvent.defaultPrevented) { return; }
      event.preventDefault();
      if (feature) {
        (feature as FeatureExt).onClickFunc();
      }
    };
  }

  prepareLayer(
    extremeMap: InstanceType<typeof ExtremeMap>,
    cars: CleaningCarExt[],
    opts: Opts, zoom: number,
  ): LayerWithInfo {
    const carsConv = cars.map(car => {
      car.pos = this.convCoord({
        lon: parseFloat(car.lon),
        lat: parseFloat(car.lat),
      });
      return car;
    });
    this.opts = opts;
    this.extremeMap = extremeMap;
    this.zoom = zoom;
    this.createCarLayer_(carsConv, opts);
    return this.getLayer();
  }

  refreshLayerOnZoomChange(): void {
    if (!this.extremeMap) return;
    this.extremeMap.redrawCleaningCarLayer({
      ...this.opts,
      fitToExtent: false,
    });
  }
}
