import axios, { AxiosResponse } from 'axios';
import * as utils from '@/apis/apiUtils';
import { EmptyResponse } from '@/models/index';
import {
  JohaisetsuMtxsCreateJohaisetsuMtxsParams,
  JohaisetsuMtxGetParams,
  JohaisetsuMtx,
} from '@/models/apis/johaisetsu/johaisetsuMtxsRequest';

export default {
  createJohaisetsuMTXs(params: JohaisetsuMtxsCreateJohaisetsuMtxsParams): Promise<AxiosResponse<EmptyResponse>> {
    return axios.post(utils.getApiUrl('/johaisetsu_mtxs'), params);
  },
  async getJohaisetsuMtxs(
    params: JohaisetsuMtxGetParams,
  ): Promise<AxiosResponse<Record<number, JohaisetsuMtx[]>>> {
    const response = await axios.get(utils.getApiUrl(`/johaisetsu_mtxs`), {
      params: params || {},
    });
    const data = response.data as Record<number, JohaisetsuMtx[]>;
    Object.values(data).forEach((mtxArray: JohaisetsuMtx[]) => {
      mtxArray.forEach((mtx: JohaisetsuMtx) => {
        mtx.lat = Number(mtx.lat);
        mtx.lon = Number(mtx.lon);
      });
    });
    return response;
  },
};
