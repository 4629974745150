import { Feature } from 'ol';
import { Icon, Style, Stroke, Fill, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { LineString, Point } from 'ol/geom';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent } from 'ol/extent';
import { Color } from 'ol/color';
import ExtremeMapAbstractLayerManager, {
  CirclePointWithTextStyleParams,
  DirectionArrowStyleParams,
  LayerWithInfo,
  DIRECTION_POINTER_INTERVAL,
} from '@/lib/ExtremeMapAbstractLayerManager';
import { IconPath } from '@/models';
import { FeatureExt } from 'src/lib/OlMapManager';
import { NamedVectorLayer } from 'src/lib/OlMapWrapper';
import { CleaningReportExt, CleaningReportPhotoExt } from '@/models/apis/cleaning/cleaningReportResponse';
import { CleaningMtxExt } from '@/models/apis/cleaning/cleaningMtxsRequest';
import {
  CLEANING_CAR_STATUS_RUNNING,
  CLEANING_CAR_STATUS_WORKING,
} from '@/components/CleaningMap/consts/cleaning_car';
import { PHOTO_TYPE_DEFECT, PHOTO_TYPE_CLEANING } from '@/components/CleaningMap/consts/cleaning_photo';
import ExtremeMap from '@/components/lib/ExtremeMap/index.vue';

interface Opts {
  hideCleaningPhotoIcons: boolean;
  hideDefectPhotoIcons: boolean;
}

interface CreateMtxPointParams {
  mtx: CleaningMtxExt;
  radius: number;
  text: string;
  zIndex: number;
  rotation: number;
}

const PHOTO_PIN_TEXT_COLOR_MAP: Record<string, string> = {
  [PHOTO_TYPE_DEFECT]: '#000000',
  [PHOTO_TYPE_CLEANING]: '#FFFFFF',
};

const ZOOM_LEVEL_1000M = 13;
const MOVING_LINE_COLOR = 'rgba(127, 127, 127, 0.8)';
const WORKING_LINE_COLOR = 'rgba(56, 241, 215, 0.8)';
const ZOOM_LEVEL_LTE_1000M_LINE_WIDTH = 4;
const ZOOM_LEVEL_GT_1000M_LINE_WIDTH = 7;
const MOVING_MTX_POINT_FILL_COLOR: Color = [157, 157, 157, 1.0];
const MOVING_MTX_POINT_STROKE_COLOR: Color = [101, 101, 101, 1.0];
const WORKING_MTX_POINT_FILL_COLOR: Color = [56, 241, 215, 1.0];
const WORKING_MTX_POINT_STROKE_COLOR: Color = [37, 161, 144, 1.0];
const ZOOM_LEVEL_LTE_1000M_MTX_POINT_RADIUS = 2.4;
const ZOOM_LEVEL_GT_1000M_MTX_POINT_RADIUS = 3.6;

export default class ExtremeMapCleaningReportLayerManager extends ExtremeMapAbstractLayerManager {
  layerName: string;
  opts: Opts;
  extremeMap: InstanceType<typeof ExtremeMap> | null;
  cleaningReport: CleaningReportExt | null;
  zoom: number;
  constructor(opts: Opts) {
    super();
    this.layerName = 'cleaning-reports';
    this.layerInfo.onLayerClick = () => {};
    this.opts = opts;
    this.extremeMap = null;
    this.cleaningReport = null;
    this.zoom = 10;
  }

  getIconPath_(photo: CleaningReportPhotoExt): IconPath {
    const iconPath = `/static/img/cleaning/cleaning_report_photo_${photo.photo_type}_pin.png`;
    const selectedFramePath = '/static/img/pin_selected.png';
    return { iconPath, selectedFramePath };
  }

  getResourceStyles_(photo: CleaningReportPhotoExt, zIndex: number): Style[] {
    const ret: Style[] = [];
    const { iconPath, selectedFramePath } = this.getIconPath_(photo);
    if (photo.isSelected) {
      ret.push(new Style({
        image: new Icon({
          src: selectedFramePath,
          anchor: [0.5, 0.82],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          scale: 0.30,
          opacity: 1.0,
        }),
        zIndex: zIndex,
      }));
    }
    ret.push(new Style({
      image: new Icon({
        src: iconPath,
        anchor: [0.5, 0.82],
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.30,
      }),
      text: new Text({
        text: photo.disp_order.toString(),
        font: 'bold 16px sans-serif',
        fill: new Fill({ color: PHOTO_PIN_TEXT_COLOR_MAP[photo.photo_type] }),
        offsetX: 0,
        offsetY: -23,
        rotation: 0,
      }),
      zIndex: zIndex,
    }));
    return ret;
  }

  createLineFeature_(points: Coordinate[], zIndex: number, status: string): Feature | null {
    // need at least 2 points to make a line.
    if (points.length < 2) {
      return null;
    }
    const line = new Feature({
      geometry: new LineString(points),
    });
    const lineColor = status === CLEANING_CAR_STATUS_RUNNING ? MOVING_LINE_COLOR : WORKING_LINE_COLOR;
    const lineWidth = this.zoom > ZOOM_LEVEL_1000M ? ZOOM_LEVEL_GT_1000M_LINE_WIDTH : ZOOM_LEVEL_LTE_1000M_LINE_WIDTH;
    line.setStyle(new Style({
      stroke: new Stroke({
        color: lineColor,
        width: lineWidth,
      }),
      zIndex: zIndex,
    }));
    (line as FeatureExt).onClickFunc = () => {};
    return line;
  }

  createCarLineFeatures_(mtxs: CleaningMtxExt[], zIndexStart: number):
    { lineFeats: Feature[]; lineZIndex: number; posCoords: Coordinate[] } {
    const lineFeats: Feature[] = [];
    const posCoords: Coordinate[] = [];
    let lineZIndex = zIndexStart;
    let movingPoints: Coordinate[] = [];
    let cleaningPoints: Coordinate[] = [];
    let currentStatus = CLEANING_CAR_STATUS_RUNNING;
    const len = mtxs.length;
    mtxs.forEach((mtx, i) => {
      const point = this.convCoord(mtx);
      if (mtx.status === CLEANING_CAR_STATUS_RUNNING) {
        movingPoints.push(point);
      }
      if (mtx.status === CLEANING_CAR_STATUS_WORKING) {
        cleaningPoints.push(point);
      }
      posCoords.push(point);
      if (i === 0) {
        currentStatus = mtx.status;
        return;
      }
      if (currentStatus !== mtx.status || i === len - 1) {
        if (currentStatus === CLEANING_CAR_STATUS_RUNNING) {
          movingPoints.push(point);
          const lineFeat = this.createLineFeature_(movingPoints, lineZIndex++, currentStatus);
          if (lineFeat) {
            lineFeats.push(lineFeat);
          }
          movingPoints = [];
        }
        if (currentStatus === CLEANING_CAR_STATUS_WORKING) {
          cleaningPoints.push(point);
          const lineFeat = this.createLineFeature_(cleaningPoints, lineZIndex++, currentStatus);
          if (lineFeat) { lineFeats.push(lineFeat); }
          cleaningPoints = [];
        }
        currentStatus = mtx.status;
      }
    });
    return { lineFeats, lineZIndex, posCoords };
  }

  createMtxPointFeatures_(
    mtxs: CleaningMtxExt[],
    zIndexStart: number,
  ): { mtxPointFeats: Feature[]; mtxPointZIndex: number } {
    const mtxPointFeats: Feature[] = [];
    let mtxPointZIndex = zIndexStart;
    const radius =
      this.zoom > ZOOM_LEVEL_1000M
        ? ZOOM_LEVEL_GT_1000M_MTX_POINT_RADIUS
        : ZOOM_LEVEL_LTE_1000M_MTX_POINT_RADIUS;
    if (mtxs.length === 0) return { mtxPointFeats, mtxPointZIndex };

    // 最初と最後のpointをそれ以外のpointよりも上に表示するために、最初と最後のpointは後に追加する
    mtxs.forEach((mtx, idx) => {
      const isFirst = idx === 0;
      const isLast = idx === mtxs.length - 1;
      if (isFirst || isLast) return;
      const nextMtx = mtxs[idx + 1];
      const rotation = this.getBearing(mtx, nextMtx);
      const text = '';
      const showDirectionIcon = idx % DIRECTION_POINTER_INTERVAL === 0;
      const mtxPointParams: CreateMtxPointParams = {
        mtx,
        radius,
        text,
        zIndex: mtxPointZIndex++,
        rotation,
      };
      const feat = showDirectionIcon
        ? this.createMtxDirectionArrowFeature_(mtxPointParams)
        : this.createMtxCirclePointFeature_(mtxPointParams);
      mtxPointFeats.push(feat);
    });
    const firstMtxPointParams: CreateMtxPointParams = {
      mtx: mtxs[0],
      radius: radius * 2.0,
      text: 'S',
      zIndex: mtxPointZIndex++,
      rotation: 0,
    };
    const firstFeat = this.createMtxCirclePointFeature_(firstMtxPointParams);
    const lastMtxPointParams: CreateMtxPointParams = {
      mtx: mtxs[mtxs.length - 1],
      radius: radius * 2.0,
      text: 'E',
      zIndex: mtxPointZIndex++,
      rotation: 0,
    };
    const lastFeat = this.createMtxCirclePointFeature_(lastMtxPointParams);
    mtxPointFeats.push(firstFeat, lastFeat);
    return { mtxPointFeats, mtxPointZIndex };
  }

  createMtxCirclePointFeature_({
    mtx,
    radius,
    text,
    zIndex,
  }: CreateMtxPointParams): Feature {
    const feat = new Feature({
      geometry: new Point(this.convCoord(mtx)),
    });
    const fillColor =
      mtx.status === CLEANING_CAR_STATUS_RUNNING
        ? MOVING_MTX_POINT_FILL_COLOR
        : WORKING_MTX_POINT_FILL_COLOR;
    const strokeColor =
      mtx.status === CLEANING_CAR_STATUS_RUNNING
        ? MOVING_MTX_POINT_STROKE_COLOR
        : WORKING_MTX_POINT_STROKE_COLOR;
    const pointStyleParams: CirclePointWithTextStyleParams = {
      radius,
      fillColor,
      strokeColor,
      zIndex,
      text,
    };
    const featStyle = text
      ? this.getCircleWithTextPointStyle(pointStyleParams)
      : this.getCirclePointStyle(pointStyleParams);
    feat.setStyle(featStyle);
    (feat as FeatureExt).onClickFunc = () => {
      if (this.extremeMap) {
        this.extremeMap.onClickCleaningMtx(mtx);
      }
    };
    return feat;
  }

  createMtxDirectionArrowFeature_({
    mtx,
    zIndex,
    rotation,
  }: CreateMtxPointParams): Feature {
    const feat = new Feature({
      geometry: new Point(this.convCoord(mtx)),
    });
    const pointStyleParams: DirectionArrowStyleParams = {
      zIndex,
      rotation,
    };
    const featStyle = this.getDirectionArrowStyle(pointStyleParams);
    feat.setStyle(featStyle);
    (feat as FeatureExt).onClickFunc = () => {
      if (this.extremeMap) {
        this.extremeMap.onClickCleaningMtx(mtx);
      }
    };
    return feat;
  }

  createPhotoFeatures_(
    photos: CleaningReportPhotoExt[],
    zIndexStart: number,
    hideCleaningPhotoIcons: boolean,
    hideDefectPhotoIcons: boolean,
  ): { photoFeats: Feature[]; photoZIndex: number } {
    const photoFeats: Feature[] = [];
    let photoZIndex = zIndexStart;
    photos.forEach(photo => {
      if (photo.photo_type === PHOTO_TYPE_CLEANING && hideCleaningPhotoIcons) { return; }
      if (photo.photo_type === PHOTO_TYPE_DEFECT && hideDefectPhotoIcons) { return; }
      const firstSamePosPhoto = photo.samePosPhotos.sort((a, b) => a.disp_order - b.disp_order)[0];
      if (photo.id !== firstSamePosPhoto.id) { return; }
      const feat = new Feature({
        geometry: new Point(this.convCoord({
          lon: parseFloat((photo.lon || 0).toString()),
          lat: parseFloat((photo.lat || 0).toString()),
        })),
      });
      feat.setStyle(this.getResourceStyles_(photo, photoZIndex++));
      (feat as FeatureExt).onClickFunc = () => {
        if (this.extremeMap) {
          this.extremeMap.onClickCleaningReportPhoto(photo);
        }
      };
      photoFeats.push(feat);
    });

    return { photoFeats, photoZIndex };
  }

  createReportLayer_(
    reportExt: CleaningReportExt,
    { hideCleaningPhotoIcons, hideDefectPhotoIcons }: Opts,
  ): void {
    const features: Feature[] = [];
    let zIndex = 1;
    const reportPosCoords: Coordinate[] = [];

    // 車両移動の軌跡を描画する
    const { lineFeats, lineZIndex, posCoords } = this.createCarLineFeatures_(reportExt.mtxsExt, zIndex);
    features.push(...lineFeats);
    zIndex = lineZIndex;
    reportPosCoords.push(...posCoords);

    // mtxsの点を描画する
    const { mtxPointFeats, mtxPointZIndex } = this.createMtxPointFeatures_(reportExt.mtxsExt, zIndex);
    features.push(...mtxPointFeats);
    zIndex = mtxPointZIndex;

    // 写真のpinを描画する
    const { photoFeats } = this.createPhotoFeatures_(
      reportExt.photosExt,
      zIndex,
      hideCleaningPhotoIcons,
      hideDefectPhotoIcons,
    );
    features.push(...photoFeats);
    const layer: NamedVectorLayer = new VectorLayer({
      source: new VectorSource({features: features as Feature[]}),
    });
    layer.name = 'cleaning-reports';
    const extent = reportPosCoords.length > 0 ? boundingExtent(reportPosCoords) : null;

    this.layer = layer;
    this.layerInfo.extent = extent;
    this.layerInfo.onLayerClick = ({ event, feature }) => {
      // 重なってたりする場合はそれぞれ飛んでくるので、一回で止める
      if (!event || event.originalEvent.defaultPrevented) { return; }
      event.preventDefault();
      if (feature) {
        (feature as FeatureExt).onClickFunc();
      }
    };
  }

  prepareLayer(
    extremeMap: InstanceType<typeof ExtremeMap>,
    reportExt: CleaningReportExt,
    opts: Opts,
    zoom: number,
  ): LayerWithInfo {
    this.extremeMap = extremeMap;
    this.opts = opts;
    this.cleaningReport = reportExt;
    this.zoom = zoom;
    this.createReportLayer_(reportExt, opts);
    return this.getLayer();
  }

  refreshLayerOnZoomChange(): void {
    if (!this.extremeMap) return;
    if (!this.cleaningReport) return;
    this.extremeMap.showCleaningReportLayer(this.cleaningReport, {
      ...this.opts,
      fitToExtent: false,
    });
  }
}
