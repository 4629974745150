import { Coordinate } from 'ol/coordinate';
import { fromLonLat, transform } from 'ol/proj';
import {
  Location,
} from 'src/models';
import { CoordinateTransformOptions, NamedVectorLayer } from '@/lib/OlMapWrapper';
import { LayerEventHandler } from '@/lib/OlMapManager';
import { Extent } from 'ol/extent';
import { Color } from 'ol/color';
import { Style, Stroke, Circle, Icon, Fill, Text } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';

export interface DirectionArrowStyleParams {
  zIndex: number;
  rotation: number;
}
export interface CirclePointStyleParams {
  radius: number;
  fillColor: Color;
  strokeColor: Color;
  zIndex: number;
}
export interface CirclePointWithTextStyleParams extends CirclePointStyleParams {
  text: string;
}

export const DIRECTION_POINTER_INTERVAL = 3;

export interface EMLayerInfo {
  onLayerClick?: LayerEventHandler;
  extent: Extent | null;
}
export interface LayerWithInfo {
  layer: NamedVectorLayer | null;
  layerInfo: EMLayerInfo;
}
export default abstract class ExtremeMapAbstractLayerManager {
  layer: NamedVectorLayer | null;
  layerInfo: EMLayerInfo;
  constructor() {
    if (this.constructor === ExtremeMapAbstractLayerManager) {
      throw new TypeError('Abstract class "ExtremeMapAbstractLayerManager" cannot be instantiated directly.');
    }
    this.layer = null;
    this.layerInfo = {
      extent: null,
    };
  }

  isArrayAllSame(a: any[], b: any[]): boolean {
    const aLen = a.length;
    const bLen = b.length;
    if (aLen !== bLen) { return false; }
    a = a.slice().sort();
    b = b.slice().sort();
    for (let i = 0; i < aLen; i++) {
      if (a[i] !== b[i]) { return false; }
    }
    return true;
  }

  coordFromLonLat(lon: number, lat: number, opt: CoordinateTransformOptions = {}): Coordinate {
    const destProj = opt.destProj || 'EPSG:3857';
    return fromLonLat([lon, lat], destProj);
  }

  convCoord({ lat, lon }: Location, opt: CoordinateTransformOptions = {}): Coordinate {
    const srcProj = opt.srcProj || 'EPSG:4326';
    const destProj = opt.destProj || 'EPSG:3857';
    return transform([lon, lat], srcProj, destProj);
  }

  getLayer(): LayerWithInfo {
    const layer = this.layer;
    const layerInfo = this.layerInfo;
    return { layer, layerInfo };
  }

  getLayerVisible(): boolean {
    if (!this.layer) { return false; }
    return this.layer.getVisible();
  }

  toggleLayerVisible(): void {
    if (!this.layer) { return; }
    const visible = this.layer.getVisible();
    this.layer.setVisible(!visible);
  }

  toRadians(angleInDegrees: number): number {
    return angleInDegrees * Math.PI / 180;
  }

  toDegrees(angleInRadians: number): number {
    return angleInRadians * 180 / Math.PI;
  }
  // from https://github.com/kokudo-digital/land-movie-pf-ap/blob/develop/src/assets/src/lib/geoCalcHelper.ts#L19
  getBearing(pt1: Location, pt2: Location): number {
    const lat1 = this.toRadians(pt1.lat);
    const lon1 = this.toRadians(pt1.lon);
    const lat2 = this.toRadians(pt2.lat);
    const lon2 = this.toRadians(pt2.lon);
    const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) -
            Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
    // from -180 to 180 degrees
    return this.toDegrees(Math.atan2(y, x)) / 180.0 * Math.PI;
  }

  getCirclePointStyle(params: CirclePointStyleParams): Style {
    const { radius, fillColor, strokeColor, zIndex } = params;
    const style = new Style({ zIndex });
    style.setImage(
      new Circle({
        radius,
        fill: new Fill({ color: fillColor }),
        stroke: new Stroke({ color: strokeColor, width: 1 }),
      }),
    );
    return style;
  }

  getCircleWithTextPointStyle(params: CirclePointWithTextStyleParams): Style {
    const { radius, fillColor, strokeColor, zIndex, text } = params;
    const style = new Style({ zIndex });
    style.setImage(
      new Circle({
        radius,
        fill: new Fill({ color: fillColor }),
        stroke: new Stroke({ color: strokeColor, width: 1 }),
      }),
    );

    const font = `${radius * 1.5}px sans-serif`;
    style.setText(
      new Text({
        text,
        font,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({
          color: 'rgba(255, 255, 255, 0.8)',
          width: 3,
        }),
        offsetX: 0,
        offsetY: 0,
      }),
    );
    return style;
  }

  getDirectionArrowStyle(params: DirectionArrowStyleParams): Style {
    const { zIndex, rotation = 0 } = params;
    const style = new Style({ zIndex });
    style.setImage(
      new Icon({
        src: '/static/img/arrow-small1.png',
        anchorXUnits: IconAnchorUnits.FRACTION,
        anchorYUnits: IconAnchorUnits.FRACTION,
        scale: 0.5,
        rotation,
      }),
    );
    return style;
  }
}
