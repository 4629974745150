export function arrayUnique<T, K extends string | number = string | number>(
  array: T[],
  uniqueBy: (item: T) => K,
): T[] {
  const seen = new Set<K>();
  const result: T[] = [];
  for (const item of array) {
    const key = uniqueBy(item);
    if (seen.has(key)) continue;
    seen.add(key);
    result.push(item);
  }
  return result;
}
