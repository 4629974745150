export const VIEW_MODE_REALTIME = 'realtime';
export const VIEW_MODE_REPORT = 'report';
export const PANE_SIDE_HARD_MAX_WIDTH = 380;
export const LIST_HARD_MAX_HEIGHT = 200;
export const VERTICAL_PANE_PADDING_NUMBER = 36;
export const PANE_SIDE_MIN_WIDTH_RATE = 0.20;
export const PANE_SIDE_MAX_WIDTH_RATE = 0.30;
export const CAR_LIST_MIN_HEIGHT_RATE = 0.60;
export const CAR_LIST_MAX_HEIGHT_RATE = 0.78;
export const REPORT_LIST_MIN_HEIGHT_RATE = 0.60;
export const REPORT_LIST_MAX_HEIGHT_RATE = 0.78;

export const statusChoices = ['全て', '移動中', '清掃中', '停止中'];
export const cleaningAreas = [
  { name: 'west', dispName: '西局', cleaningRole: 't-west' },
  { name: 'east', dispName: '東局', cleaningRole: 't-east' },
  { name: 'kanagawa', dispName: '神奈川', cleaningRole: 'kanagawa' },
];
export const CONNECTION_CHAR = '、';

export const CLEANING_CAR_MAX_MTXS = 1000;
export const CLEANING_REPORT_MAX_MTXS = 1000;
