

























import {
  defineComponent,
  toRefs,
  reactive,
  ref,
} from '@vue/composition-api';
import axios from 'axios';
import { useStore } from '@/hooks/useStore';
import { LocalStorageActionTypes } from '@/store/modules/localStorage';
import { UserActionTypes } from '@/store/modules/user';
import { getRedirectDefaultPageUrl } from '@/lib/defaultPageHelper';

interface LoginState {
 loginForm: {
    username: string;
    password: string;
  };
  showErrorMessage: boolean;
}

export default defineComponent({
  name: 'login',
  setup() {
    const state = reactive<LoginState>({
      loginForm: {
        username: '',
        password: '',
      },
      showErrorMessage: false,
    });
    // methods
    // iOSのchromeだとautofillでchangeが効かないぽいのでがんばる
    const textInputUsername = ref<HTMLInputElement>();
    const textInputPassword = ref<HTMLInputElement>();

    const ensureLoginFormValuesSynced = () => {
      const username = textInputUsername.value?.value;
      const password = textInputPassword.value?.value;
      state.loginForm.username = username || '';
      state.loginForm.password = password || '';
    };
    const store = useStore();
    const tryLogin = async(): Promise<void> => {
      state.showErrorMessage = false;
      ensureLoginFormValuesSynced();

      // loginしようとするときは明示的に消す.
      // expiredなトークンを送り続けるとログインできないので.
      delete axios.defaults.headers['Authorization'];

      try {
        const res = await store.dispatch(UserActionTypes.LOGIN, state.loginForm);
        if ('two_factor_url' in res) {
          location.href = res['two_factor_url'] + '&subsystem_name=rt-strm';
        } else if ('access_token' in res && 'user' in res) {
          await store.dispatch(LocalStorageActionTypes.SET, {
            key: 'serverToken',
            val: res['access_token'],
          });
          location.href = getRedirectDefaultPageUrl({
            isJohaisetsuOnly: res.user.is_johaisetsu_only,
            isCleaningOnly: res.user.is_cleaning_only,
            defaultPage: res.user.default_page,
          });
        }
      } catch (e) {
        state.showErrorMessage = true;
      }
    };

    return {
      ...toRefs(state),
      // refs
      textInputUsername,
      textInputPassword,
      // methods
      ensureLoginFormValuesSynced,
      tryLogin,
    };
  },
});
